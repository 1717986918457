<template>
  <div>
    
    <div class="contents">
      <submenu curbg=0></submenu>
      <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="90px">
                <!-- <el-form-item label="创建时间" style="margin-bottom:10px">

                    <el-date-picker v-model="searchForm.createTimeList" type="datetimerange"
                        :default-time="['00:00:00', '23:59:59']"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始时间" end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item> -->

                <el-form-item label="客户名称" style="margin-bottom:10px">

                  <el-input v-model="searchForm.name" clearable  style="width:190px"
                    placeholder="请输入客户名称"></el-input>
                </el-form-item>

                <el-form-item label="客户编码" style="margin-bottom:10px">

                  <el-input v-model="searchForm.code" clearable  style="width:190px"
                    placeholder="请输入客户编码"></el-input>
                </el-form-item>

                <!-- <el-form-item label="计重方式" style="margin-bottom:10px">


                    <el-select v-model="searchForm.weightType"
                    clearable
                    multiple
                    filterable
                    placeholder="请选择计重方式">
                        <el-option
                              v-for="item in enums['WmsCustomerWeightTypeEnum'].filter(it => it.searchable)"
                              :key="item.value"
                              :label="item.name"
                              :value="item.value">
                            </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="出库计费方式" style="margin-bottom:10px">


                    <el-select v-model="searchForm.outType"
                    clearable
                    multiple
                    filterable
                    placeholder="请选择出库计费方式">
                        <el-option
                              v-for="item in enums['WmsCustomerOutTypeEnum'].filter(it => it.searchable)"
                              :key="item.value"
                              :label="item.name"
                              :value="item.value">
                            </el-option>
                    </el-select>
                </el-form-item> -->

                <el-form-item label="手机号" style="margin-bottom:10px">

                  <el-input v-model="searchForm.phone" clearable  style="width:190px"
                    placeholder="请输入手机号"></el-input>
                </el-form-item>

                <span class="buttons">
                    <el-button @click="createSearch" type="primary" icon="el-icon-search" :loading="loading">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </span>
          </el-form>
      </div>

    <div class="mainbox">

     <vxe-toolbar ref="xToolbar" custom>
            <template #buttons>
              <el-button type="primary" icon="el-icon-plus" size="small" @click="handleAdd">添加</el-button>
              <!-- &nbsp;&nbsp;
              <el-dropdown type="primary" @command=handleCommand>
                  <el-button type="primary" plain >
                    批量操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown" >
                    <el-dropdown-item command="batchDelete">批量删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown> -->
            </template>
            <template #tools>
            </template>
      </vxe-toolbar>

      <vxe-table border
        id="table"
        empty-text="没有更多数据"
        header-align="center"
        stripe
        :data="tableData"
        :custom-config="{storage: true, resizable: true}"
        :column-config="{resizable: true}"
        :row-config="{isCurrent: true, isHover: true, keyField: 'id'}"
        size="small"
        @checkbox-change="handleSelectionChange"
         ref="table">
          <vxe-column type="checkbox" width="40" fixed></vxe-column>

        <vxe-column
            field="name"
            title="客户名称"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.name}}
          </template>
        </vxe-column>

        <vxe-column
            field="code"
            title="客户编码"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.code}}
          </template>
        </vxe-column>

      <!--
        <vxe-column
            field="weightType"
            title="计重方式"
            min-width="50"
        >
          <template slot-scope="scope">
                {{enumMaps['WmsCustomerWeightTypeEnum'][scope.row.weightType]}}
          </template>
        </vxe-column>

         <vxe-column
            field="outType"
            title="出库计费方式"
            min-width="50"
        >
          <template slot-scope="scope">
                {{enumMaps['WmsCustomerOutTypeEnum'][scope.row.outType]}}
          </template>
        </vxe-column> 

        <vxe-column
            field="businessCode"
            title="营业执照编号"
            min-width="50"
        >
          <template slot-scope="scope">
                {{scope.row.businessCode}}
          </template>
        </vxe-column>

        <vxe-column
            field="contractCode"
            title="合同编号"
            min-width="50"
        >
          <template slot-scope="scope">
                {{scope.row.contractCode}}
          </template>
        </vxe-column>
    -->
        <vxe-column
            field="owner"
            title="负责人"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.owner}}
          </template>
        </vxe-column>

        <vxe-column
            field="phone"
            title="手机号"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.phone}}
          </template>
        </vxe-column>

        <vxe-column
            field="address"
            title="地址"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.address}}
          </template>
        </vxe-column>

        <vxe-column
            field="email"
            title="邮箱"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.email}}
          </template>
        </vxe-column>

        <vxe-column
            field="remark"
            title="备注"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.remark}}
          </template>
        </vxe-column>

        <vxe-column
            field="createTime"
            title="创建时间"
            min-width="160"
        >
          <template slot-scope="scope">
                {{scope.row.createTime}}
          </template>
        </vxe-column>



        <vxe-column title="操作" fixed="right" width="160">
            <template slot-scope="scope">
                  <el-button class="c-button" @click="handleDetail(scope.row)" type="text" size="small">详情</el-button>
                  <el-button class="c-button" @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
                  <!-- <el-popconfirm title="确定要删除吗？删除后不可恢复，请谨慎操作" @confirm="del(scope.row)">
                    <el-button class="c-button" slot="reference" type="text" size="small">删除</el-button>
                  </el-popconfirm> -->
             </template>
        </vxe-column>

      </vxe-table>

      <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 50, 100, 200, 400]"
            @current-change="changePage" :current-page="page" :page-size="pageSize" @size-change="handleSizeChange"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>

    <customer-add ref="wmsCustomerAdd" @success="getTopSearch"></customer-add>
    <customer-detail ref="wmsCustomerDetail" @success="getTopSearch"></customer-detail>
    <customer-edit ref="wmsCustomerEdit" @success="getTopSearch"></customer-edit>

  </div>
</template>

<script>
import Vue from 'vue'
import Clipboard from 'clipboard'
import submenu from "@/components/submenu"
import customerAdd from "./customerAdd"
import customerDetail from "./customerDetail"
import customerEdit from "./customerEdit"

import { page, detail, del, batchDelete } from "@/api/wms/customer"
import { getEnums } from "@/api/common"

let moment = require('moment')
Vue.prototype.Clipboard = Clipboard

export default {
  name: 'WmsCustomer',
  components: { submenu, customerAdd, customerDetail, customerEdit },
  data() {
    return {
      searchForm: {
      },
      total: 0,
      page: 1,
      counts: 0,
      pageSize: 20,
      tableData: [],
      enums: {
        WmsCustomerWeightTypeEnum: [],
        WmsCustomerOutTypeEnum: [],
      },
      enumMaps: {},
      loading: false,
      multipleSelection: [],
    }
  },
  methods: {
    initEnums() {
        const param = {
            names: Object.keys(this.enums)
        }
        getEnums(param).then(res=>{
            if(res.code===200) {
              this.enums = res.data.enums
              Object.keys(this.enums).forEach(key => {
                let map = {}
                this.enums[key].forEach(obj => map[obj.value] = obj.name)
                this.enumMaps[key] = map
              })
            } else {
                this.$message.error(res.message)
            }
        })
    },
    handleCommand(arg) {
        console.log("handleCommand", arg)
        if(arg == "batchDelete") {
            return this.handleBatchDelete()
        }
    },
    handleBatchDelete() {
        console.log("handleBatchDelete", this.multipleSelection)
        if(this.multipleSelection.length ==0) {
            return this.$message.warning("请选择要批量删除的记录")
        }
        this.$confirm('确定要删除这'+ this.multipleSelection.length +'条数据吗，一旦删除不可恢复，请谨慎操作?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                 const param = {
                    ids: this.multipleSelection.map(it => it.id)
                 }
                 batchDelete(param).then(res => {
                    if(res.code===200) {
                        this.$message.success(res.message)
                        this.getTopSearch()
                    } else {
                        this.$message.error(res.message)
                    }
                 })
        }).catch(err=>{})
    },
    handleAdd() {
      this.$refs.wmsCustomerAdd.onOpen()
    },
    handleDetail(row) {
      this.$refs.wmsCustomerDetail.onOpen(row)
    },
    handleEdit(row) {
      this.$refs.wmsCustomerEdit.onOpen(row)
    },
    handleSelectionChange(val) {
        console.log("handleSelectionChange", val)
      this.multipleSelection = val.records
    },
    del(row) {
      const param = {
          id: row.id
      }
      del(param).then(res=>{
          if(res.code===200) {
            this.$message.success(res.message)
            this.getTopSearch()
          } else {
            this.$message.error(res.message)
          }
      })
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getTopSearch()
    },
    createSearch() {
      this.page = 1
      this.getTopSearch()
    },
    reset(){
      this.searchForm={
        date: ''
      }
      this.page = 1
      this.getTopSearch()
    },
    getTopSearch(){
      var that = this
      var param = that.searchForm
      param.pageNo = that.page
      param.pageSize = that.pageSize
      param.orderByCreated = "DESC"
      that.loading = true
      page(param).then(res => {
        that.loading = false
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          that.tableData = nodataArr
          that.total = res.data.totalRows
          that.counts = res.data.counts
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        that.loading = false
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
  },
  mounted(){
    this.initEnums()
    this.getTopSearch()
  }
}
</script>
<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}

.red {
  color: rgb(236, 78, 15);
}
.c-button{
  margin-left:8px;
}
.buttons {
}
</style>