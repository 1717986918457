<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @close="onClose"
    title="添加货主客户"
    :visible.sync="visible">

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="100px">


        <el-row :gutter="10">

        <el-col :span="12">
        <el-form-item label="客户名称" prop="name">


          <el-input v-model="row.name" placeholder="请输入客户名称" clearable
            :style="{width: '200px'}"></el-input>

        </el-form-item>
        </el-col>



        <el-col :span="12">
        <el-form-item label="客户编码" prop="code">

              <span>{{row.code}}</span>

        </el-form-item>
        </el-col>
        </el-row>

        <el-row :gutter="10">

        <el-col :span="12">
        <el-form-item label="营业执照编号" prop="businessCode">


          <el-input v-model="row.businessCode" placeholder="请输入营业执照编号" clearable
            :style="{width: '200px'}"></el-input>

        </el-form-item>
        </el-col>



        <el-col :span="12">
        <el-form-item label="合同编号" prop="contractCode">


          <el-input v-model="row.contractCode" placeholder="请输入合同编号" clearable
            :style="{width: '200px'}"></el-input>

        </el-form-item>
        </el-col>
        </el-row>


        <el-row :gutter="10">

        <el-col :span="12">
        <el-form-item label="负责人" prop="owner">


          <el-input v-model="row.owner" placeholder="请输入负责人" clearable
            :style="{width: '200px'}"></el-input>

        </el-form-item>
        </el-col>



        <el-col :span="12">
        <el-form-item label="手机号" prop="phone">


          <el-input v-model="row.phone" placeholder="请输入手机号" clearable
            :style="{width: '200px'}"></el-input>

        </el-form-item>
        </el-col>
        </el-row>


        <el-row :gutter="10">

        <el-col :span="12">
        <el-form-item label="地址" prop="address">


          <el-input v-model="row.address" placeholder="请输入地址" clearable
            :style="{width: '200px'}"></el-input>

        </el-form-item>
        </el-col>



        <el-col :span="12">
        <el-form-item label="邮箱" prop="email">


          <el-input v-model="row.email" placeholder="请输入邮箱" clearable
            :style="{width: '200px'}"></el-input>

        </el-form-item>
        </el-col>
        </el-row>


        <el-row :gutter="10">

        <el-col :span="12">
        <el-form-item label="附件" prop="attachment">


                <el-upload
                  class="upload-file"
                  action="action"
                  :show-file-list="false"
                  :on-remove="() => row.attachment=''"
                  :on-success="(url) => row.attachment=url"
                  :before-remove="(file, fileList) => fileList = []"
                  :http-request="upload"
                  :file-list="row.attachment?[{name:row.attachment,url:row.attachment}]:[]"
                  >
                  <el-button size="small" type="primary">点击上传</el-button>
                  &nbsp;&nbsp;
                  <span><el-link type="info"
                   v-if="row.attachment"
                  target="_blank" :href="row.attachment">查看文件</el-link></span>
                </el-upload>

        </el-form-item>
        </el-col>



        <el-col :span="12">
        <el-form-item label="备注" prop="remark">


              <el-input v-model="row.remark" placeholder="请输入备注"
                    clearable
                    type="textarea"
                    :rows="3"
                    :style="{width: '200px'}"
                ></el-input>

        </el-form-item>
        </el-col>
        </el-row>


      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums } from "@/api/common"
import { edit } from "@/api/wms/customer"
import { uploadFile } from "@/api/common";

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
      visible: false,
      row: {
      },
      rules: {
        id: [
        {
          required: true,
          message: '请输入ID',
          trigger: 'blur'
        }],
        orgId: [
        {
          required: true,
          message: '请输入所属机构',
          trigger: 'blur'
        }],
        name: [
        {
          required: true,
          message: '请输入客户名称',
          trigger: 'blur'
        }],
        weightType: [
        {
          required: true,
          message: '请输入计重方式',
          trigger: 'blur'
        }],
        outType: [
        {
          required: true,
          message: '请输入出库计费方式',
          trigger: 'blur'
        }],
      },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsCustomerWeightTypeEnum')
          names.push('WmsCustomerOutTypeEnum')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen(row) {
        this.visible = true
        this.row = row
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },
    upload(param){
        console.log("upload", param)
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success('上传成功')
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        edit(param).then(res=>{
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>

</style>
