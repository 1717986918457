<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @close="onClose"
    title="货主客户详情"
    :visible.sync="visible">

      <el-descriptions class="margin-top" :column="2"
      border>


            <el-descriptions-item label="客户ID">
            {{ row.id }}
            </el-descriptions-item>


            <el-descriptions-item label="创建时间">
            {{ row.createTime }}
            </el-descriptions-item>




            <el-descriptions-item label="客户名称">
            {{ row.name }}
            </el-descriptions-item>

            <el-descriptions-item label="客户编码">
            {{ row.code }}
            </el-descriptions-item>

            <el-descriptions-item label="计重方式" v-if="enumMaps['WmsCustomerWeightTypeEnum']">
                 {{enumMaps['WmsCustomerWeightTypeEnum'][row.weightType]}}
            </el-descriptions-item>

            <el-descriptions-item label="出库计费方式" v-if="enumMaps['WmsCustomerOutTypeEnum']">
                 {{enumMaps['WmsCustomerOutTypeEnum'][row.outType]}}
            </el-descriptions-item>

            <el-descriptions-item label="营业执照编号">
            {{ row.businessCode }}
            </el-descriptions-item>

            <el-descriptions-item label="合同编号">
            {{ row.contractCode }}
            </el-descriptions-item>

            <el-descriptions-item label="负责人">
            {{ row.owner }}
            </el-descriptions-item>

            <el-descriptions-item label="手机号">
            {{ row.phone }}
            </el-descriptions-item>

            <el-descriptions-item label="地址">
            {{ row.address }}
            </el-descriptions-item>

            <el-descriptions-item label="邮箱">
            {{ row.email }}
            </el-descriptions-item>

            <el-descriptions-item label="附件">
              <el-link type="info"
              v-if="row.attachment"
              :href="row.attachment" target="_blank">文件地址</el-link>
            </el-descriptions-item>

            <el-descriptions-item label="备注">
            {{ row.remark }}
            </el-descriptions-item>

      </el-descriptions>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums } from "@/api/common"
import { detail } from "@/api/wms/customer"

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
        visible: false,
        row: {
            id: '',
            createTime: '',
            name: '',
            code: '',
            weightType: '',
            outType: '',
            businessCode: '',
            contractCode: '',
            owner: '',
            phone: '',
            address: '',
            email: '',
            attachment: '',
            remark: '',
        },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsCustomerWeightTypeEnum')
          names.push('WmsCustomerOutTypeEnum')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    detail() {
        const param = {
            id: this.row.id
        }
        detail(param).then(res=>{
          if(res.code === 200) {
            this.row = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    onOpen(row) {
        console.log("onOpen", row)
        this.visible = true
        this.row = row
        this.detail()
    },
    onClose() {
    },
    close() {
      this.visible = false
    },
  }
}

</script>
<style>
</style>
